import './Sidebar.scss';
import { useSelector, useDispatch } from 'react-redux';
import catService from '../../services/catService';
import { useEffect, useState } from 'react';
import SidebarItem from './components/SidebarItem/SidebarItem';
import { FiCodesandbox } from 'react-icons/fi';
import { BeatLoader } from 'react-spinners';
import { motion } from 'framer-motion';
import OrderIcon from '../../icons/OrderIcon/OrderIcon';
import { FiUsers, FiSettings } from 'react-icons/fi';
import SettingsIcon from '../../icons/SettingsIcon/SettingsIcon';
import StatisticIcon from '../../icons/StatisticIcon/StatisticIcon';
import IntegrIcon from '../../icons/IntegrIcon/IntegrIcon';
import TrashIcon from '../../icons/TrashIcon/TrashIcon';
import { BsBag } from 'react-icons/bs';
import { AiOutlineStar } from 'react-icons/ai';
import { GoPerson } from 'react-icons/go';
import { TbArticle } from 'react-icons/tb';
import { BiMessageDetail } from 'react-icons/bi';
import { catalogUpdate, handleSidebarOpen } from '../../store/actions';
import { RiReservedLine } from 'react-icons/ri';
import { TbTruckDelivery } from 'react-icons/tb';
import { IoNewspaperOutline } from 'react-icons/io5';
import { LuFlame } from 'react-icons/lu';
import { MdBlock } from 'react-icons/md';
import { FaPlus } from 'react-icons/fa6';
import { IoBriefcaseSharp } from 'react-icons/io5';
import { BsQuestion } from 'react-icons/bs';
import { MdMenuOpen } from 'react-icons/md';
import { MdOutlineLoyalty } from 'react-icons/md';
import anService from '../../services/anService';
import { MdOutlineScreenshotMonitor } from 'react-icons/md';
import OrgsIcon from '../../assets/img/OrgsIcon';
import CatalogIcon from '../../assets/img/CatalogIcon';
import StoriesIcon from '../../assets/img/StoriesIcon';
import SettingIcon from '../../assets/img/SettingsIcon';
import AnalyticsIcon from '../../assets/img/AnalyticsIcon';

const cs = new catService();
const ans = new anService();

const Sidebar = () => {
    const { token, catalog, user } = useSelector((state) => state);
    const [catLoad, setCatLoad] = useState(false);
    const [isHide, setIsHide] = useState(false);
    const [totalAppOrders, setTotalAppOrders] = useState(0);
    const [totalSiteOrders, setTotalSiteOrders] = useState(0);
    const { settingsFront } = useSelector((s) => s);
    const dispatch = useDispatch();
    const address = window.location.origin;
    useEffect(() => {
        setCatLoad(true);
        if (token) {
            cs.getCats(token, { OrganisationID: 0 })
                .then((res) => {
                    // setCats(res)
                    dispatch(catalogUpdate(res));
                })
                .finally((_) => {
                    setCatLoad(false);
                });
        }
    }, [token]);

    const toggleSidebar = () => {
        setIsHide(!isHide);
    };

    useEffect(() => {
        if (isHide) {
            dispatch(handleSidebarOpen(false));
        } else {
            dispatch(handleSidebarOpen(true));
        }
    }, [isHide]);

    const getOrders = (platform) => {
        if (token) {
            // setPage(1)
            const body = {
                Field: 'id',
                Offset: 0,
                OrderBy: 'ID',
                Search: '',
                OrderType: 'DESC',
                Limit: 50,
                Archive: 0,
                platform: platform,
            };
            ans.getOrders(token, body)
                .then((res) => {
                    if (platform == 0) {
                        setTotalAppOrders(Number(res?.TotalCountNewOrders));
                    } else if (platform == 1) {
                        setTotalSiteOrders(Number(res?.TotalCountNewOrders));
                    }
                })
                .finally((_) => {});
            ans.getStatuses(token).then((res) => {});
        }
    };

    useEffect(() => {
        getOrders(0);
        getOrders(1);
    }, []);

    return (
        <>
            <div className={'Sidebar-pl' + (isHide ? ' hide ' : '')}></div>
            <motion.div
                initial={{ translateX: '-100%' }}
                animate={{ translateX: 0 }}
                transition={{ duration: 0.5 }}
                exit={{ translateX: '-100%' }}
                className={'Sidebar gs-scroll' + (isHide ? ' hide ' : '')}
            >
                <div className='Sidebar__head'></div>
                {user && (
                    <div className='Sidebar__list'>
                        {settingsFront?.adminPanel && (
                            <SidebarItem
                                labelHide={isHide}
                                name={'Админ панель'}
                                link={'/admin-panel?p=Админ панель'}
                                root={'/admin-panel'}
                                icon={<SettingsIcon size={22} />}
                            />
                        )}

                        {user?.Role === 'Admin' && (
                            <>
                                {settingsFront?.OrgsPage && (
                                    <SidebarItem
                                        labelHide={isHide}
                                        name={'Организации'}
                                        root={'/organizations'}
                                        link={`/organizations?p=Организации`}
                                        icon={<OrgsIcon />}
                                    />
                                )}
                                {settingsFront?.CatalogPage && (
                                    <SidebarItem
                                        labelHide={isHide}
                                        toggleSidebar={setIsHide}
                                        name={'Каталог'}
                                        root={'/catalog'}
                                        link={'/catalog?p=Каталог'}
                                        isSubmenu={catalog?.length > 0 ? true : false}
                                        icon={<CatalogIcon />}
                                    >
                                        {catLoad ? (
                                            <div className='SidebarItem__load'>
                                                <BeatLoader color='var(--violet)' />
                                            </div>
                                        ) : catalog && catalog.length > 0 ? (
                                            catalog.map((item, index) => (
                                                <SidebarItem
                                                    key={index}
                                                    labelHide={isHide}
                                                    icon={<FiCodesandbox />}
                                                    name={item.Name}
                                                    root={`/catalog/${item.ID}`}
                                                    link={`/catalog/${item.ID}?p=Каталог&p=${item.Name}`}
                                                />
                                            ))
                                        ) : null}
                                    </SidebarItem>
                                )}
                                {
                                    <>
                                        {settingsFront?.AppOrdersPage && (
                                            <SidebarItem
                                                labelHide={isHide}
                                                name={'Заказы с приложения'}
                                                link={'/orders-app?p=Заказы с приложения'}
                                                root={'/orders-app'}
                                                icon={<OrderIcon size={22} />}
                                                badge={totalAppOrders}
                                            />
                                        )}
                                        {settingsFront?.SiteOrdersPage && (
                                            <SidebarItem
                                                labelHide={isHide}
                                                name={'Заказы с сайта'}
                                                link={'/orders-site?p=Заказы с сайта'}
                                                root={'/orders-site'}
                                                icon={<OrderIcon size={22} />}
                                                badge={totalSiteOrders}
                                            />
                                        )}
                                    </>
                                }
                                {
                                    <>
                                        {settingsFront?.CatalogHits && (
                                            <SidebarItem
                                                labelHide={isHide}
                                                name={'Хиты категорий'}
                                                link={'/categories-hits?p=Хиты категорий'}
                                                root={'/categories-hits'}
                                                icon={<LuFlame size={22} />}
                                            />
                                        )}
                                        {settingsFront?.CatalogHits && (
                                            <SidebarItem
                                                labelHide={isHide}
                                                name={'Хиты блюд'}
                                                link={'/plates-hits?p=Хиты блюд'}
                                                root={'/plates-hits'}
                                                icon={<LuFlame size={22} />}
                                            />
                                        )}
                                    </>
                                }
                                {settingsFront?.StoriesPageYm && (
                                    <SidebarItem
                                        labelHide={isHide}
                                        name={'Сториз'}
                                        link={'/stories-ym?p=Сториз'}
                                        root={'/stories-ym'}
                                        icon={<StoriesIcon></StoriesIcon>}
                                    />
                                )}
                                {settingsFront?.StoriesPage && (
                                    <SidebarItem
                                        labelHide={isHide}
                                        name={'Сториз'}
                                        link={'/stories?p=Сториз'}
                                        root={'/stories'}
                                        icon={<StoriesIcon></StoriesIcon>}
                                    />
                                )}
                            </>
                        )}
                        {(settingsFront?.StatPage ||
                            settingsFront?.ReservPage ||
                            settingsFront?.ClientsPage ||
                            settingsFront?.OrdersPage ||
                            settingsFront?.RevsPage ||
                            settingsFront?.SiteRevsPage) && (
                            <SidebarItem
                                labelHide={isHide}
                                name={'Аналитика'}
                                isSubmenu={true}
                                toggleSidebar={setIsHide}
                                // icon={<StatIcon size={22} />}
                                icon={<AnalyticsIcon></AnalyticsIcon>}
                            >
                                {user?.Role === 'Admin' && (
                                    <>
                                        {settingsFront?.StatPage && (
                                            <SidebarItem
                                                labelHide={isHide}
                                                name={'Статистика'}
                                                link={'/statistic?p=Статистика'}
                                                root={'/statistic'}
                                                icon={<StatisticIcon size={22} />}
                                            />
                                        )}
                                        {settingsFront?.ReservPage && (
                                            <SidebarItem
                                                labelHide={isHide}
                                                name={'Брони'}
                                                link={'/reservations?p=Брони'}
                                                root={'/reservations'}
                                                icon={<RiReservedLine size={22} />}
                                            />
                                        )}
                                    </>
                                )}
                                {settingsFront?.ClientsPage && (
                                    <SidebarItem
                                        labelHide={isHide}
                                        name={'Клиенты'}
                                        link={'/clients?p=Клиенты'}
                                        root={'/clients'}
                                        icon={<FiUsers />}
                                    />
                                )}

                                {settingsFront?.OrdersPage && (
                                    <SidebarItem
                                        labelHide={isHide}
                                        name={'Заказы'}
                                        link={'/orders?p=Заказы'}
                                        root={'/orders'}
                                        icon={<OrderIcon size={22} />}
                                    />
                                )}

                                {settingsFront?.RevsPage && (
                                    <SidebarItem
                                        labelHide={isHide}
                                        name={'Оценки'}
                                        link={'/revs?p=Оценки'}
                                        root={'/revs'}
                                        icon={<AiOutlineStar size={22} />}
                                    />
                                )}
                                {settingsFront?.SiteRevsPage && (
                                    <SidebarItem
                                        labelHide={isHide}
                                        name={'Отзывы с сайта'}
                                        link={'/revs-site?p=Отзывы с сайта'}
                                        root={'/revs-site'}
                                        icon={<AiOutlineStar size={22} />}
                                    />
                                )}
                            </SidebarItem>
                        )}
                        {user?.Role === 'Admin' &&
                            (settingsFront?.BasketPage ||
                                settingsFront?.IntegrPage ||
                                settingsFront?.SettingsPage ||
                                settingsFront?.RatesetPage ||
                                settingsFront?.Loyalty ||
                                settingsFront?.Users ||
                                settingsFront?.TrashPage ||
                                settingsFront?.VacanciesPage ||
                                settingsFront?.ArticlesPage ||
                                settingsFront?.PayDeliveryPage ||
                                settingsFront?.NewsPage ||
                                settingsFront?.NavigationPage ||
                                settingsFront?.DeliveryRules ||
                                settingsFront?.Accessibility ||
                                settingsFront?.Partnership) && (
                                <SidebarItem
                                    labelHide={isHide}
                                    name={'Настройки'}
                                    toggleSidebar={setIsHide}
                                    isSubmenu={true}
                                    // icon={<SettingsIcon size={22} />}
                                    icon={<SettingIcon></SettingIcon>}
                                >
                                    {settingsFront?.BasketPage && (
                                        <SidebarItem
                                            root={'/basket'}
                                            labelHide={isHide}
                                            name={'Корзина'}
                                            link={'/basket?p=Настройки корзины'}
                                            icon={<BsBag />}
                                        />
                                    )}
                                    {settingsFront?.IntegrPage && (
                                        <SidebarItem
                                            labelHide={isHide}
                                            root={'/integr'}
                                            name={'Интеграции'}
                                            link={'/integr?p=Интеграции'}
                                            icon={<IntegrIcon size={22} />}
                                        />
                                    )}

                                    {settingsFront?.SettingsPage && (
                                        <SidebarItem
                                            labelHide={isHide}
                                            name={'Все настройки'}
                                            link={'/allsettings?p=Все настройки'}
                                            root={'/allsettings'}
                                            icon={<FiSettings />}
                                        />
                                    )}
                                    {settingsFront?.RatesetPage && (
                                        <SidebarItem
                                            labelHide={isHide}
                                            name={'Настройки отзывов'}
                                            link={'/ratingSettings?p=Настройки отзывов'}
                                            root={'/ratingSettings'}
                                            icon={<BiMessageDetail size={22} />}
                                        />
                                    )}
                                    {settingsFront?.Loyalty && (
                                        <SidebarItem
                                            labelHide={isHide}
                                            name={'Уровни лояльности'}
                                            link={'/loyalty?p=Уровни лояльности'}
                                            root={'/loyalty'}
                                            icon={<MdOutlineLoyalty size={22} />}
                                        />
                                    )}
                                    {settingsFront?.TrashPage && (
                                        <SidebarItem
                                            labelHide={isHide}
                                            name={'Удаленные объекты'}
                                            link={'/trash?p=Удаленные обьекты'}
                                            root={'/trash'}
                                            icon={<TrashIcon size={22} />}
                                        />
                                    )}
                                </SidebarItem>
                            )}
                        {(settingsFront?.BannersSquare ||
                            settingsFront?.BannersRectangular) && (
                            <SidebarItem
                                labelHide={isHide}
                                name={'Баннеры'}
                                link={'/banners?p=Баннеры'}
                                root={'/banners'}
                                icon={<MdOutlineScreenshotMonitor size={22} />}
                            />
                        )}

                        {settingsFront?.VacanciesPage && (
                            <SidebarItem
                                labelHide={isHide}
                                name={'Вакансии'}
                                link={'/vacancies?p=Вакансии'}
                                root={'/vacansies'}
                                icon={<GoPerson size={22} />}
                            />
                        )}
                        {settingsFront?.ArticlesPage && (
                            <SidebarItem
                                labelHide={isHide}
                                name={'Акции'}
                                link={'/articles?p=Акции'}
                                root={'/articles'}
                                icon={<TbArticle size={22} />}
                            />
                        )}

                        {settingsFront?.PayDeliveryPage && (
                            <SidebarItem
                                labelHide={isHide}
                                name={'Доставка и оплата'}
                                link={'/pay-delivery?p=Доставка и оплата'}
                                root={'/pay-delivery'}
                                icon={<TbTruckDelivery size={22} />}
                            />
                        )}
                        {settingsFront?.Bonuses && (
                            <SidebarItem
                                labelHide={isHide}
                                name={'Бонусы и программы'}
                                link={'/bonuses-program?p=Бонусы и программы'}
                                root={'/bonuses-program'}
                                icon={<FaPlus size={22} />}
                            />
                        )}
                        {settingsFront?.Partnership && (
                            <SidebarItem
                                labelHide={isHide}
                                name={'Сотрудничество'}
                                link={'/partnership?p=Сотрудничество'}
                                root={'/partnership'}
                                icon={<IoBriefcaseSharp size={22} />}
                            />
                        )}
                        {settingsFront?.NewsPage && (
                            <SidebarItem
                                labelHide={isHide}
                                name={'Новости'}
                                link={'/news?p=Новости'}
                                root={'/news'}
                                icon={<IoNewspaperOutline size={22} />}
                            />
                        )}
                        {settingsFront?.NavigationPage && (
                            <SidebarItem
                                labelHide={isHide}
                                name={'Навигация'}
                                link={'/navigation?p=Навигация'}
                                root={'/navigation'}
                                icon={<MdMenuOpen size={22} />}
                            />
                        )}
                        {settingsFront?.DeliveryRules && (
                            <SidebarItem
                                labelHide={isHide}
                                name={'Правила доставки'}
                                link={'/delivery-rules?p=Правила доставки'}
                                root={'/delivery-rules'}
                                icon={<BsQuestion size={22} />}
                            />
                        )}
                        {settingsFront?.Accessibility && (
                            <SidebarItem
                                labelHide={isHide}
                                name={'Блокировка'}
                                link={'/accessibility?p=Блокировка'}
                                root={'/accessibility'}
                                icon={<MdBlock size={22} />}
                            />
                        )}
                    </div>
                )}
            </motion.div>
        </>
    );
};

export default Sidebar;
