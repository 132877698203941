import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './Bonuses.scss';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';
import Button from '../../components/Button/Button';
import Pl from '../../components/Pl/Pl';
import { Modal } from 'antd';
import Input from '../../components/Input/Input';
import TextArea from 'antd/lib/input/TextArea';
import { useNavigate } from 'react-router-dom';
import { BsTrash } from 'react-icons/bs';
import ReactQuill, { Quill } from 'react-quill';
import { htmlEditButton } from 'quill-html-edit-button';

Quill.register('modules/htmlEditButton', htmlEditButton);

const Bonuses = () => {
    const { token } = useSelector((state) => state);
    const navigate = useNavigate();
    const [pageContent, setPageContent] = useState([]);
    const [blocks, setBlocks] = useState([]);
    const [isViewBonusModalOpen, setIsViewBonusModalOpen] = useState(false);
    const [isEditVacancyModalOpen, setIsEditVacancyModalOpen] = useState(false);
    const [isViewBlockModalOpen, setIsViewBlockModalOpen] = useState(false);
    const [isEditBlockModalOpen, setIsEditBlockModalOpen] = useState(false);
    const [modalTitle, setModaltitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [modalId, setModalId] = useState(null);
    const [blockTitle, setBlockTitle] = useState(null);
    const [blockContent, setBlockContent] = useState(null);
    const [blockId, setBlockId] = useState(null);
    const showViewBonusModal = (title, content, id) => {
        setModaltitle(title);
        // const replaceNbspWithBr = (htmlString) => {
        //   return htmlString.replace(/(&nbsp;)+/g, '<br/>');
        // };
        setModalContent(content);
        setModalId(id);
        setIsViewBonusModalOpen(true);
    };
    const handleViewBonusModalCancel = () => {
        setIsViewBonusModalOpen(false);
    };
    const showEditVacancyModal = () => {
        setIsEditVacancyModalOpen(true);
    };
    const handleEditVacancyModalCancel = () => {
        setIsEditVacancyModalOpen(false);
    };
    const showViewBlockModal = (title, content, id) => {
        setBlockTitle(title);
        setBlockContent(content);
        setBlockId(id);
        setIsViewBlockModalOpen(true);
    };
    const handleViewBlockModalCancel = () => {
        setIsViewBlockModalOpen(false);
    };
    const showEditBlockModal = () => {
        setIsEditBlockModalOpen(true);
    };
    const handleEditBlockModalCancel = () => {
        setIsEditBlockModalOpen(false);
    };

    const getBonuses = async () => {
        try {
            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ page: 'BonusesProgram' }),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }

            const responseData = await response.json();
            setPageContent(responseData.content.ru.slice(0, 8));
            setBlocks(responseData.content.ru[8].blocks);
            console.log(responseData.content.ru);
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    useEffect(() => {
        getBonuses();
    }, []);

    const editBonuses = async (id) => {
        try {
            const updatedPageContent = pageContent.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        title: modalTitle,
                        text: modalContent,
                    };
                }
                return item;
            });
            const requestBody = {
                page: 'BonusesProgram',
                content: {
                    ru: [...updatedPageContent, { blocks: [...blocks] }],
                },
            };

            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }

            const responseData = await response.json();
            notification.success({ message: 'Карточка успешно отредактирована' });
            handleEditVacancyModalCancel();
            setModaltitle(modalTitle);
            setModalContent(modalContent);
            // setLocalDescr("");
            // setLocalBlock("");
            getBonuses();
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    const editBlock = async (id) => {
        try {
            const updatedBlockContent = blocks.map((item) => {
                if (item.id === id) {
                    return { ...item, title: blockTitle, descr: blockContent };
                }
                return item;
            });
            console.log(updatedBlockContent);
            const requestBody = {
                page: 'BonusesProgram',
                content: {
                    ru: [...pageContent, { blocks: [...updatedBlockContent] }],
                },
            };

            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }

            const responseData = await response.json();
            notification.success({ message: 'Успешно отредактировано' });
            handleEditBlockModalCancel();
            getBonuses();
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    const handleDeleteBonus = async (id) => {
        try {
            const updatedBlocks = pageContent.filter((item) => item.id !== id);

            const requestBody = {
                page: 'BonusesProgram',
                content: {
                    ru: [...updatedBlocks, { blocks: [...blocks] }],
                },
            };

            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }

            const responseData = await response.json();
            notification.success({ message: 'Блок успешно удалён' });
            handleViewBlockModalCancel();
            getBonuses();
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    const handleDeleteBlock = async (id) => {
        try {
            const updatedBlocks = blocks.filter((item) => item.id !== id);

            const requestBody = {
                page: 'BonusesProgram',
                content: {
                    ru: [...pageContent, { blocks: [...updatedBlocks] }],
                },
            };

            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }

            const responseData = await response.json();
            notification.success({ message: 'Блок успешно удалён' });
            handleViewBlockModalCancel();
            getBonuses();
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link', 'image', 'video'],
            ['clean'],
        ],
        htmlEditButton: {},
    };

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
    ];

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0 }}
            className='page'
        >
            <div className='pageBody'>
                <div className='VacanciesPage__body pageBody-content'>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr 1fr',
                            gap: '30px',
                        }}
                    >
                        {pageContent
                            ? pageContent.map((item) => (
                                  <Pl
                                      style={{
                                          width: '100%',
                                          height: '200px',
                                          backgroundColor: '#fff',
                                      }}
                                      text={item.title}
                                      onClick={() =>
                                          showViewBonusModal(
                                              item.title,
                                              item.text,
                                              item.id
                                          )
                                      }
                                  ></Pl>
                              ))
                            : null}
                    </div>
                    <Modal
                        open={isViewBonusModalOpen}
                        title={modalTitle}
                        onCancel={handleViewBonusModalCancel}
                        footer={[]}
                        width={580}
                    >
                        <div className='QAPage__item DeliveryPage__item'>
                            <div className='QAPage__item-label'>Заголовок</div>
                            <p
                                style={{ textAlign: 'left', marginBottom: '0px' }}
                                className='DeliveryPage__modal-title'
                            >
                                {modalTitle}
                            </p>
                        </div>
                        <div className='QAPage__item DeliveryPage__item'>
                            <div className='QAPage__item-label'>Текст</div>
                            <p
                                style={{ textAlign: 'left' }}
                                className='DeliveryPage__modal-descr'
                            >
                                {modalContent ? parse(modalContent) : null}
                            </p>
                        </div>
                        <div className='VacanciesPage__modal-wrapper'>
                            <Button
                                key='back'
                                onClick={() => handleDeleteBonus(modalId)}
                                text={'Удалить'}
                                variant={'danger'}
                                before={<BsTrash />}
                            />
                            <Button
                                key='back'
                                onClick={showEditVacancyModal}
                                text={'Редактировать'}
                            />
                        </div>
                        <Modal
                            open={isEditVacancyModalOpen}
                            title='Редактировать'
                            onCancel={handleEditVacancyModalCancel}
                            footer={[]}
                            width={680}
                        >
                            <div className='QAPage__item'>
                                <div className='QAPage__item-label'>Заголовок</div>
                                <Input
                                    style={{
                                        border: '1.5px solid rgba(176, 194, 255, .6)',
                                        borderRadius: '13px',
                                    }}
                                    maskType={String}
                                    shadow
                                    onChange={(e) => {
                                        setModaltitle(e.target.value);
                                    }}
                                    value={modalTitle}
                                />
                            </div>
                            <div className='DeliveryPage__item'>
                                <div className='DeliveryPage__item-label'>Текст</div>
                                {/* <TextArea
                  className="DeliveryPage__textarea"
                  value={modalContent}
                  onChange={(e) => {
                    setModalContent(e.target.value);
                  }}
                  rows={4}
                ></TextArea> */}
                                <ReactQuill
                                    value={modalContent}
                                    onChange={setModalContent}
                                    style={{ height: '200px', color: 'black' }}
                                    formats={formats}
                                    modules={modules}
                                />
                            </div>
                            <div
                                style={{ marginTop: '80px' }}
                                className='VacanciesPage__modal-wrapper'
                            >
                                <Button
                                    key='back'
                                    onClick={handleEditVacancyModalCancel}
                                    text={'Отмена'}
                                    variant={'danger'}
                                />
                                <Button
                                    key='back'
                                    onClick={() => editBonuses(modalId)}
                                    text={'Сохранить'}
                                />
                            </div>
                        </Modal>
                    </Modal>
                    <div className='BonusesPage__bottom'>
                        <p className='BonusesPage__text'>Блоки снизу: </p>
                        <Button
                            text={'Добавить'}
                            onClick={() => {
                                navigate('/bonuses-program/create');
                            }}
                        ></Button>
                    </div>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr 1fr',
                            gap: '30px',
                            marginTop: '20px',
                        }}
                    >
                        {blocks
                            ? blocks.map((item) => (
                                  <Pl
                                      style={{
                                          width: '100%',
                                          height: '200px',
                                          backgroundColor: '#fff',
                                      }}
                                      text={item.title}
                                      onClick={() =>
                                          showViewBlockModal(
                                              item.title,
                                              item.descr,
                                              item.id
                                          )
                                      }
                                  ></Pl>
                              ))
                            : null}
                        <Modal
                            open={isViewBlockModalOpen}
                            title={blockTitle}
                            onCancel={handleViewBlockModalCancel}
                            footer={[]}
                            width={580}
                        >
                            <div className='ArticlePage__modal-text'>Текст</div>
                            <p className='VacanciesPage__modal-descr'>
                                {blockContent ? parse(blockContent) : null}
                            </p>
                            <div className='VacanciesPage__modal-wrapper'>
                                <Button
                                    key='back'
                                    onClick={() => handleDeleteBlock(blockId)}
                                    text={'Удалить'}
                                    variant={'danger'}
                                    before={<BsTrash />}
                                />
                                <Button
                                    key='back'
                                    onClick={showEditBlockModal}
                                    text={'Редактировать'}
                                />
                            </div>
                            <Modal
                                open={isEditBlockModalOpen}
                                title='Редактировать'
                                onCancel={handleEditBlockModalCancel}
                                footer={[]}
                                width={880}
                            >
                                <div className='VacanciesPage__item'>
                                    <div className='VacanciesPage__item-label'>
                                        Заголовок
                                    </div>
                                    <Input
                                        style={{
                                            border: '1.5px solid rgba(176, 194, 255, .6)',
                                            borderRadius: '13px',
                                        }}
                                        maskType={String}
                                        shadow
                                        onChange={(e) => {
                                            setBlockTitle(e.target.value);
                                        }}
                                        value={blockTitle}
                                    />
                                </div>
                                <div className='VacanciesPage__item-label'>Описание</div>
                                {/* <TextArea
                  className="vacanciesPage__textarea"
                  value={modalContent}
                  onChange={(e) => {setLocalDescr(e.target.value); setModalContent(e.target.value)}}
                  rows={4}
                ></TextArea> */}
                                <ReactQuill
                                    value={blockContent ? blockContent : null}
                                    onChange={(e) => {
                                        setBlockContent(e);
                                    }}
                                    style={{ height: '200px', color: 'black' }}
                                    formats={formats}
                                    modules={modules}
                                />
                                <div
                                    style={{ marginTop: '80px' }}
                                    className='VacanciesPage__modal-wrapper'
                                >
                                    <Button
                                        key='back'
                                        onClick={handleEditBlockModalCancel}
                                        text={'Отмена'}
                                        variant={'danger'}
                                    />
                                    <Button
                                        key='back'
                                        onClick={() => editBlock(blockId)}
                                        text={'Сохранить'}
                                    />
                                </div>
                            </Modal>
                        </Modal>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default Bonuses;
